import axios from "axios";
import authHeader from "../helpers/header";
import helper from "../helpers/query";
import apiInstance from "./api";

import { API_URL, NODE_URL } from "../configs";

//const API_URL = "http://127.0.0.1:8000/";
const exclude_tags = [
  "of",
  "a",
  "an",
  "the",
  "or",
  "for",
  "any",
  "some",
  "with",
  "these",
  "to",
  "your",
  "you",
  "that",
  "this",
  "types",
  "type",
  "best",
  "use",
  "other",
  "free",
  "view",
  "are",
  "is",
  "new",
  "different",
  "vector",
  "and",
];

// create resource
const createResource = (data) => {
  let tags = [];
  let list = [];
  //prrocess tag name
  if (data.tags !== "") {
    list = data.tags.split(",");
    //generate tag base on name of resource
  } else {
    list = data.name.split(" ");
  }
  //process tags data
  for (let i = 0; i < list.length; i++) {
    let new_tag = list[i].trim().replace(/[^\w\s]/gi, "");
    //exclude unuse words and check if it is duplicated
    if (!exclude_tags.includes(list[i].trim()) && tags.indexOf(new_tag) < 0) {
      tags.push({ name: new_tag });
    }
  }

  return axios.post(
    API_URL + "resources",
    {
      name: data.name,
      description: data.description,
      license: data.license,
      status: data.status,
      categories: data.categories,
      tags: tags,
      images: data.images,
      mainImage: data.mainImage,
    },
    { headers: authHeader() }
  );
};

// get resource by slug
const getResourceBySlug = (slug) => {
  return axios.get(API_URL + `resources/${slug}`);
};
// get resource by id
const getResourceById = (id) => {
  return axios.get(API_URL + `resources/by_id/${id}`);
};

// change delete method to update status
const removeResource = (id) => {
  return axios.delete(API_URL + `resources/${id}`);
};

// change delete method to update status
const bulkUpdateResource = (data) => {
  return axios.post(
    API_URL + `resources/bulkUpdate`,
    {
      data,
    },
    { headers: authHeader() }
  );
};

// change delete method to update status
const bulkUpdateResourceMeta = (data) => {
  return axios.post(
    API_URL + `resources/bulkUpdateMeta`,
    {
      data,
    },
    { headers: authHeader() }
  );
};

// get related tag by tag name
const getRelatedTag = (tag) => {
  return axios.get(API_URL + `resources/related_tags/${tag}`);
};

/**
 * generate resource color by resource id
 * @param {str} id
 * @returns json data
 */
const generateResourceThumbnail = (id) => {
  return axios.get(API_URL + `datas/thumbnail/${id}`);
};

/**
 * generate resource color by resource id
 * @param {str} id
 * @returns json data
 */
const generateResourceColors = (id) => {
  return axios.get(API_URL + `datas/colors/${id}`);
};

/**
 * generate resource data by resource id
 * @param {str} id
 * @returns json data
 */
const generateResourceData = (id) => {
  return axios.get(API_URL + `datas/regenerate/${id}`);
};

/**
 * generate resource meta by resource id
 * @param {str} id
 * @returns json data
 */
const generateResourceMeta = (id) => {
  return axios.get(API_URL + `datas/meta/${id}`);
};

/**
 * Get simlar resources by resource id
 * @param {str} id
 * @returns
 */
const getSimilarResourceById = (id) => {
  //return axios.get(API_URL + `resources/similar/${id}`);
  return axios.get(API_URL + `collections/by_resource_id/${id}`);
};

/**
 * Get simlar resources by resource id
 * @param {str} id
 * @returns
 */
const getSameCollectionbyResourceId = (id) => {
  return axios.get(API_URL + `collections/by_resource_id/${id}`);
};

// update resource data
const updateResource = (id, resource) => {
  let tags = [];
  //process tags data
  for (let i = 0; i < resource.tags.length; i++) {
    //exclude unuse words
    if (!exclude_tags.includes(resource.tags[i].name.trim())) {
      tags.push({
        name: resource.tags[i].name.trim().replace(/[^\w\s]/gi, ""),
      });
    }
  }
  return axios.patch(API_URL + `resources/${id}`, {
    name: resource.name,
    description: resource.description,
    //creator: resource.creator,
    license: resource.license,
    status: resource.status,
    slug: resource.slug,
    is_picked: resource.is_picked,
    tags: tags,
    images: resource.images,
    mainImage: resource.mainImage,
    metaSeo: {
      metaTitle: resource.meta_title,
      metaDescription: resource.meta_description,
      metaKeywords: resource.meta_keywords,
      canonicalURL: resource.meta_canonicalURL,
      redirectURL: resource.meta_redirectURL,
    },
  });
};
// get all resource base condition
const getAllResources = async (query) => {
  //build query
  let newQuery = {};

  if (query.q) {
    //console.log(decodeURI(query.q).replaceAll(" ", "+"))
    //newQuery.q = decodeURI(query.q).replaceAll(" ", "+")
    newQuery.q = query.q;
  } else {
    newQuery.q = "";
  }

  if (query.tags) {
    newQuery.tags = decodeURI(query.tags).replaceAll(" ", "+");
  }

  if (query.category) {
    newQuery.category = query.category;
  }

  if (query.preview) {
    newQuery.preview = query.preview;
  }

  if (query.created_by) {
    newQuery.created_by = query.created_by;
  }

  if (query.created_from) {
    newQuery.created_from = query.created_from;
  }

  if (query.created_to) {
    newQuery.created_to = query.created_to;
  }

  if (query.status) {
    newQuery.status = query.status;
  }

  if (query.page) {
    newQuery.offset = parseInt(query.page) - 1;
    //newQuery.offset = parseInt(query.page)
    // }else{
    //   newQuery.offset = 0
  }

  if (query.pageSize) {
    newQuery.limit = parseInt(query.pageSize);
  }

  if (query.sortby) {
    newQuery.sortby = query.sortby;
  }

  if (query.direction) {
    newQuery.direction = query.direction;
  }

  let q = helper.arrayToQueryString(newQuery);
  const response = await apiInstance.get(API_URL + `resources/?${q}`, {
    headers: authHeader(),
  });
  return response.data;
};

// get resource categories list from resouce data
// const getCategories = () => {
//   return axios.get(API_URL + `resources/categories/all`);
// };

// const limit = (count, p) => `limit=${count}&offset=${p}`;
// //const omitSlug = article => Object.assign({}, article, { slug: undefined })
// const getAll = (page, limit = 5) => {

//   let url = '';
//   //if(parseInt(page-1) === 0) {
//   url = API_URL + `resources?limit=${limit}&offset=${(parseInt(page) - 1) * limit}`
//   // }else{
//   //   url = API_URL+`resources?limit=${limit}&offset=${parseInt(page-1)*limit}`
//   // }
//   console.log(url)
//   return axios.get(url)
// }

const generateResourcesInfoWithGemini = async (data) => {
  return axios.post(
    NODE_URL + `/api/resources/generate/gemini`,
    data
    // { headers: authHeader() }
  );
};

const generateResourcesInfoWithOpenai = async (data) => {
  return axios.post(
    NODE_URL + `/api/resources/generate/openai`,
    data
    // { headers: authHeader() }
  );
};

const regenerateWithOpenai = async (data) => {
  return axios.post(
    NODE_URL + `/api/resources/regenerate/openai`,
    {
      ...data,
    }
    // { headers: authHeader() }
  );
};

const regenerateWithGemini = async (data) => {
  return axios.post(
    NODE_URL + `/api/resources/regenerate/gemini`,
    {
      ...data,
    }
    // { headers: authHeader() }
  );
};

const getMergeImgeBase64 = async (data) => {
  return axios.post(
    NODE_URL + `/api/resources//merge-thumbnail`,
    {
      ...data,
    }
    // { headers: authHeader() }
  );
};

const exportedObject = {
  createResource,
  updateResource,
  removeResource,
  //getAll,
  getAllResources,
  getResourceBySlug,
  getResourceById,
  getSimilarResourceById,
  getSameCollectionbyResourceId,
  //getCategories,
  bulkUpdateResource,
  bulkUpdateResourceMeta,
  getRelatedTag,
  generateResourceColors,
  generateResourceData,
  generateResourceThumbnail,
  generateResourceMeta,
  // generate with ai
  generateResourcesInfoWithGemini,
  generateResourcesInfoWithOpenai,
  regenerateWithOpenai,
  regenerateWithGemini,
  getMergeImgeBase64,
};

export default exportedObject;
