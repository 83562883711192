import axios from "axios";
import authHeader from "../helpers/header";
// import helper from "../helpers/query";
// import apiInstance from "./api";

import { API_URL } from "../configs";

// change delete method to update status
const createNewCollection = (data) => {
  return axios.post(
    API_URL + `collections/`,
    {
      data,
    },
    { headers: authHeader() }
  );
};

// get collection detail by collection id
const getCollectionById = (id) => {
  return axios.get(API_URL + `collections/by_id/${id}`);
};

/**
 * Remove a collection by ID (updating status to 'deleted').
 * 
 * This async function sends a DELETE request to the FastAPI backend to mark 
 * a collection as 'deleted'. The collection ID is passed as a parameter, and the 
 * function returns the result of the API call.
 * 
 * @param {string} id - The ID of the collection to be deleted.
 * @returns {Object} - The response data from the API.
 * @throws {Error} - Throws an error if the API request fails.
 */
const removeCollection = async (id) => {
  try {
    // Construct the API URL using the collection ID
    const url = `${API_URL}collections/${id}`;
    // Send a DELETE request to the API and await the response
    const response = await axios.delete(url);
    return response;
  } catch (error) {
    // Handle any errors that occur during the request
    console.error('Error deleting collection:', error);
    throw error;  // Propagate the error so it can be handled by the caller
  }
};



// get collection 's resources by collection id
const getResourceFromCollection = (id) => {
  return axios.get(API_URL + `collections/resources_from/${id}`);
};

// get list of collection contain resources
const getCollectionHasResource = (resource_id) => {
  return axios.get(API_URL + `collections/has_resource/${resource_id}`);
};

const exportedObject = {
  createNewCollection,
  getCollectionById,
  removeCollection,
  getResourceFromCollection,
  getCollectionHasResource
};

export default exportedObject;
