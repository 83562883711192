import axios from "axios";
import authHeader from "../../helpers/header";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import { setMessage } from "../../redux/actions/message";

import AuthLayout from "../../components/AuthLayout/AuthLayout";
import { API_URL, MEDIA_URL } from "../../configs";


function ResourcesTags(props) {
    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      formState: { errors },
    } = useForm();
    
    const { message, messageType } = props;

    const dispatch = useDispatch();
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)

    const [loading, setLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [resourcesData, setResourceData] = useState([]);

    /**
     * Add tag
     * @param {array} tag
     */
    const handleTags = async (tag) => {
      //get new tag to get related
      let new_tag = tag.filter((x) => !selectedTags.includes(x));
      //process if paste value with (,)
      if (new_tag[0] && new_tag[0].split(",")) {
        //remove new_tag from tag
        tag.splice(tag.indexOf(new_tag), 1);
        //append splited value to tag
        let tmp = new_tag[0].split(",");
        tmp.map((element) => {
          //Add only none exist tag
          if (tag.indexOf(element) === -1) {
            tag.push(element);
            //fetchRelatedTags(element);
          }
        });
      }
      //set selected tag state
      setSelectedTags(tag);
      //process tags input value
      let tag_values = getValues("new_tags").split(",");
  
      tag.map((t) => {
        // console.log(t.length)
        if (t !== "" && !tag_values.includes(t)) {
          //avoid adding empty value
          if (getValues("new_tags").length > 0) {
            tag_values.push(t);
          } else {
            tag_values = [t];
          }
        }
      });
      //set tags value
      setValue("new_tags", tag_values.join(","));
      console.log(selectedTags)
    };

    /**
   * Remove tag
   * @param {string} tag
   */
    const handleRemoveTag = (tag) => {
      let tag_values = getValues("new_tags").split(",");
      let index = tag_values.indexOf(tag);
      if (index > -1) {
        //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
        tag_values.splice(index, 1);
      }
      setValue("new_tags", tag_values.join(","));
    };

    // Submit updated tags to the backend
    const handleUpdate = async (data) => {
      setLoading(true);
      try {
        const response = await axios.put(API_URL + `resources/bulkUpdateTags`, {data}, { headers: authHeader() }); // Adjust API route as necessary
        
        if (response.status === 200) {
          console.log(response.data)
          dispatch(
            setMessage(
              `Tags has been updated succesfull`,
              "success"
            )
          );
          setValue("new_tags", '')
        } else {
          //setErrorMessage(`Error: ${response.data.message || 'Failed to update tags'}`);
          console.log(response.data.message)
          dispatch(setMessage(`There was an error while updating tag`, "danger"));
        }
      } catch (error) {
        console.error('Error updating tags:', error);
        dispatch(setMessage(`${error}`, "danger"));
        //setErrorMessage('Error updating tags. Please try again.');
      } finally {
        setLoading(false); // Stop the loading state after the request is complete
      }
    };

    useEffect(() => {
        let ids = searchParams.get('resources_id')
        //set resource_ids value
        setValue('resource_ids', ids)
        //const fetchRelatedTags = async (tag) => {
        async function getResourcesData(ids) {
            const res = await axios.get(API_URL + `resources/getbulk/?ids=${ids}`)
            if(res.status === 200){
              setResourceData(res.data)
            }
        }
        getResourcesData(ids)
    }, [])

    return (
        <AuthLayout>
            <header className={"d-flex justify-content-between align-items-center mb-4"}>
                <h2>{"Tags update"}</h2>
            </header>
            {message && (
              <div className="mb-4">
                <div className={`alert alert-${messageType}`} role="alert">
                  {message}
                </div>
              </div>
            )}
            <div className="row">
                <div className="col-md-6">
                {resourcesData && 
                  <>
                    {resourcesData.map((data, index) => {
                        return(
                            <div key={index} className="row mb-4">
                                <div className="col-1">
                                  <img className="img-thumbnail" src={`${MEDIA_URL}${data.coverImages.path}`} with="200" />
                                </div>
                                <div className="col-10">
                                  <p className="text-left mt-0 mb-2 py-0">
                                  <Link to={`/resources/view/${data._id}`} target="_blank" rel="noreferrer">{data.name}</Link><br />
                                      {/* <Link to={`/resources/view/${data._id}`} target="_blank" rel="noreferrer">
                                          <span className="mt-1 small text-secondary fw-normal py-1">{data._id}</span>
                                      </Link> */}
                                  </p>
                                  <p className="text-muted text-left mb-2 py-0">
                                      {data.tags &&
                                        data.tags.map((tag, i) => {
                                          return(
                                            <span key={i} className="me-2 my-1 rounded-pill d-inline-block bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">{tag.name}</span>
                                          )
                                        })
                                      }
                                  </p>
                                </div>
                            </div>
                        )
                    })
                    }
                  </>
                }
                </div>
                <div className="col-md-6">
                  <form onSubmit={handleSubmit(handleUpdate)}>
                    <div className="mb-3">
                      <label htmlFor="tags" className="form-label">
                        Enter new tags
                      </label>
                      <input
                        className="form-control"
                        value={''}
                        hidden
                        {...register("new_tags", { required: true })}
                      />
                      <TagsInput
                        value={''}
                        onChange={handleTags}
                        onRemoved={handleRemoveTag}
                        name="input-new-tags"
                        placeHolder="Enter new tags"
                      />
                      {errors.description && (
                        <span className="small text-danger">
                          This field is required
                        </span>
                      )}
                    </div>
                    <input
                      type={"hidden"}
                      {...register("resource_ids")}
                    />
                    <button className="btn btn-primary">Update</button>
                  </form>
                </div>
            </div>
        </AuthLayout>
    );
}
function mapStateToProps(state) {
    const { token } = state.auth;
    
    const { message, messageType } = state.message;
    return {
        token,
        message,
        messageType,
    };
}

export default connect(mapStateToProps)(ResourcesTags);
      