import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom'; // or use useNavigate if you're using React Router v6

//import { useForm } from "react-hook-form";
import Moment from "react-moment";
import TimeAgo from "react-timeago";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { connect, useDispatch } from "react-redux";
import CollectionAPI from "../../api/collectionAPI";
import UserAPI from "../../api/userAPI";
import AuthLayout from "../../components/AuthLayout/AuthLayout";

import { setMessage } from "../../redux/actions/message";

import format from "../../helpers/format";
import { MEDIA_URL, API_URL, FE_WEBSITE_URL } from "../../configs";

//export default function ResourceDetail(props) {
function CollectionDetail(props) {

    const { id } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [collection, setCollection] = useState({});
    const [collectionResources, setCollectionResources] = useState([]);
    const [userData, setUserData] = useState({});
    const dispatch = useDispatch();
    const { token, user, message, messageType } = props;

    //state use to handle form submit
    const { register, setValue, handleSubmit } = useForm();
    const navigate = useNavigate(); // or useNavigate() if using React Router v6

    const onSubmit = async (data) => {
        console.log(data)
        const resourceIds = data.resource_ids.split(',').map(id => id.trim());
        const format = data.format.trim();
    
        const resourceIdsParam = resourceIds.join(',');
        const formatParam = encodeURIComponent(format);
        // Create the URL with query parameters
        const url = `/resources/processFile?resources_id=${resourceIdsParam}&format=${formatParam}`;
        console.log(url)
        // Redirect to the new URL
        navigate(url);
    };

    // Fetch owner data
    const fetchOwnerData = async (id) => {
        try {
            const response = await UserAPI.getUser(id);
            setUserData(response);
        } catch (e) {
            dispatch(setMessage(`${e}`, "danger"));
        }
    };

    // Fetch collection data
    const fetchData = async (collectionId) => {
        try {
            const response = await CollectionAPI.getCollectionById(collectionId);
            setCollection(response.data);
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    // Fetch collection resources
    const getCollectionResources = async (collectionId) => {
        try {
            const response = await CollectionAPI.getResourceFromCollection(collectionId);
            setCollectionResources(response.data);
            setValue( 'resource_ids', response.data 
                ? response.data.map(resource => resource._id).join(',') // Join without adding extra comma
                : '')
        } catch (e) {
            console.log(e);
        }
    };
    
    const deleteCollection = async (id) => {
        try {
            const response = await CollectionAPI.removeCollection(id);
            console.log(response)
            if (response.status === 204) {
                dispatch(setMessage(`Collection ${id} was deleted`, "success"));
                const updatedCollection = await fetchData(id); // Re-fetch the collection after deletion
                setCollection(updatedCollection);            // Update state with the re-fetched collection data
            }else{
                dispatch(setMessage(`There was an error occur, try again!`, "danger"));
            }
        } catch (e) {
            dispatch(setMessage(`${e}`, "danger"));
        }
    };

    // First useEffect to fetch data
    useEffect(() => {
        (async () => {
            await fetchData(id);
            await getCollectionResources(id);
        })();
    }, [id]);

    // Second useEffect to fetch owner data based on collection.ownerId
    useEffect(() => {
        (async () => {
            if (collection.ownerId) {
                await fetchOwnerData(collection.ownerId);
            }
        })();
    }, [collection?.ownerId]);

    return (
        <AuthLayout>
          {isLoading ? (
            <>
              <p>{'Loading...'}</p>
            </>
          ) : (
            <>
            <header
                className={
                "d-md-flex d-sm-inline justify-content-between align-items-center mb-4 gap-3"
                }
            >
                <div className="d-flex align-items-center">
                    {collection.collection_name && 
                        <h2 className="mb-2 me-2">{collection.collection_name}</h2>
                    }       
                </div>
                {(collection.status && collection.status !== 'deleted' )?
                (
                <button
                    className="btn btn-danger mx-1"
                    onClick={(e) => {
                        e.preventDefault();
                        deleteCollection(collection._id);
                    }}
                    >Delete</button>
                ):('')}
            </header>
            {message && (
                <div className="mb-4">
                <div className={`alert alert-${messageType}`} role="alert">
                    {message}
                </div>
                </div>
            )}

            <div>
                <div className="row">
                    <div className="col-md-8">
                        <dl className="row mb-5">
                            <dt className="col-sm-2 fw-normal text-muted mb-2">Status</dt>
                            <dd className="col-sm-10">
                                <span>{format.formatStatus(collection.status)}</span>
                            </dd>
                            <dt className="col-sm-2 fw-normal text-muted mb-2">
                            Created Date
                            </dt>
                            <dd className="col-sm-10">
                            <Moment format="YYYY-MM-DD HH:mm:ss">
                                {collection.created_at}
                            </Moment>
                            <span className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                <i className="bi bi-clock me-1"></i>
                                <span className="">
                                <TimeAgo date={collection.created_at} />
                                </span>
                            </span>
                            {/* {new Date(resource.created_at).toDateString()} */}
                            </dd>
                            <dt className="col-sm-2 fw-normal text-muted mb-2">Private</dt>
                            <dd className="col-sm-10">
                                {collection.private}
                            </dd>
                            <dt className="col-sm-2 fw-normal text-muted mb-2">Slug</dt>
                            <dd className="col-sm-10">
                                <span>{collection.slug}</span>
                            </dd>
                            <dt className="col-sm-2 fw-normal text-muted mb-2">Owner</dt>
                            <dd className="col-sm-10">
                                {userData &&
                                    <p>{userData.email}</p>
                                }  
                            </dd>
                            <dt className="col-sm-2 fw-normal text-muted mb-2">Tags</dt>
                            <dd className="col-sm-10">
                            {collection.tags &&
                                collection.tags.length > 0 &&
                                collection.tags.map((tag, i) => {
                                return (
                                    <span
                                    className="badge bg-white border rounded me-2 text-dark fw-normal fs-6 my-1 py-2 px-3 suggested-tag"
                                    key={i}
                                    >
                                    {tag.name}
                                    {/* {i < resource.tags.length - 1 && ','} */}
                                    </span>
                                );
                                })}
                            </dd>
                        </dl>
                    </div>
                    <div className="col-md-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <label htmlFor="resource_ids" className="d-none">Resource IDs (comma separated):</label>
                                <input
                                id="resources_id"
                                type="hidden"
                                // value={collectionResources 
                                //     ? collectionResources.map(resource => resource._id).join(',') // Join without adding extra comma
                                //     : ''}
                                {...register('resource_ids', { required: true })}
                                placeholder="Enter resource IDs, comma separated"
                                />
                            </div>

                            <div>
                                <label htmlFor="format" className="form-label">Select file's format to download</label><br />
                                <div className="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="svg"
                                    value="svg"
                                    className="form-check-input" 
                                    {...register('format', { required: true })}
                                />
                                <label className="form-check-label" htmlFor="svg">SVG</label>
                                </div>
                                <div className="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="ai"
                                    value="ai"
                                    className="form-check-input" 
                                    {...register('format', { required: true })}
                                />
                                <label className="form-check-label" htmlFor="ai">AI</label>
                                </div>
                                <div className="form-check form-check-inline">
                                <input
                                    type="radio"
                                    id="eps"
                                    value="eps"
                                    className="form-check-input" 
                                    {...register('format', { required: true })}
                                />
                                <label className="form-check-label" htmlFor="eps">EPS</label>
                                </div>
                            </div>
                            <button type="submit" className="mt-2 btn btn-secondary">Get Files</button>
                            <br />
                            {collectionResources 
                            ? (
                                
                                <a className="mt-2 btn btn-outline-secondary" target="_blank" href={`/resources/processImage?resources_id=${collectionResources.map(resource => resource._id).join(',')}`}>Get Images</a>
                              )
                            : ('')
                            }
                        </form>               
                    </div>
                </div>
                <div className="row border-top-0 gx-2">
                    {collectionResources && 
                    collectionResources.map((resource, index) => {
                        return (
                            <div
                            key={index}
                            className={`col-2 mb-4 rgrid-item align-middle`}
                            >
                            <div
                                className={`rcontent position-relative p-3 border 
                                    `
                            }
                            >
                                {/* {selectedId.indexOf(resource._id.trim()) > -1 ? (
                                <div className="item-check position-absolute">
                                    <i className="bi bi-check-lg text-success fs-5"></i>
                                </div>
                                ) : (
                                ""
                                )} */}
                                <div className="text-center">
                                {resource?.thumbnail?.path ? (
                                    <>
                                    {/* <PreviewImage
                                        image={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                        height={120}
                                        alt={resource.name}
                                        /> */}
                                    {/* <img src={`http://localhost/magipik-fastapi/${resource.preview[0].path}`} width={80} className='me-auto'/> */}

                                    {resource?.originalImage?.path ? (
                                        <a
                                        href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        >
                                        <LazyLoadImage
                                            alt={resource.name}
                                            src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                            height={160}
                                            effect="blur"
                                            className="img-preview"
                                        />
                                        </a>
                                    ) : (
                                        <>
                                        <LazyLoadImage
                                            alt={resource.name}
                                            src={`${MEDIA_URL}${resource?.thumbnail?.path}`}
                                            height={160}
                                            effect="blur"
                                            className="img-preview"
                                        />
                                        </>
                                    )}
                                    </>
                                ) : (
                                    <>
                                    {resource?.originalImage?.path && (
                                        <a
                                        href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        >
                                        <LazyLoadImage
                                            alt={resource.name}
                                            src={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                            height={160}
                                            delayMethod="debounce"
                                            effect="blur"
                                            className="img-preview"
                                        />
                                        </a>
                                    )}
                                    </>
                                )}
                                </div>
                                <div className="d-flex justify-content-start align-items-center py-1">
                                <span>
                                    {format.formatResourceStatusNoText(resource.status)}
                                </span>
                                <div className="small mt-1 ms-2">
                                    <span className="ms-2">
                                    {format.formatResourceOrientation(
                                        resource.orientation
                                    )}
                                    </span>
                                </div>
                                </div>
                                <div>
                                <Link to={`/resources/view/${resource._id}`}>
                                    <strong className="rname small">
                                    {resource.name}
                                    </strong>
                                </Link>
                                <div className="d-flex align-items-center py-1 small">
                                    <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                                    {resource.slug.split("-").pop()}
                                    </small>
                                    {resource.metaSeo &&
                                    resource.metaSeo.canonicalURL ? (
                                    <>
                                        <span className="badge bg-primary bg-opacity-10 text-primary rounded-pill ms-2">
                                        {"C"}
                                        </span>
                                        <span className="badge bg-light ms-1 text-dark fw-normal ">
                                        {resource.metaSeo.canonicalURL
                                            .split("-")
                                            .pop()}
                                        </span>
                                    </>
                                    ) : (
                                    ""
                                    )}
                                    {resource.metaSeo &&
                                    resource.metaSeo.redirectURL ? (
                                    <>
                                        <span className="badge bg-danger rounded-pill ms-2">
                                        {"R"}
                                        </span>
                                        <span className="badge bg-light ms-1 text-dark">
                                        {resource.metaSeo.redirectURL
                                            .split("-")
                                            .pop()}
                                        </span>
                                    </>
                                    ) : (
                                    ""
                                    )}
                                </div>
                                </div>

                                <div className="d-none rdetail p-3 position-absolute top-0 bottom-0 start-0 end-0">
                                {/* <dl className="row mb-5">
                                    <dt className="col-sm-3 fw-normal text-muted mb-2">Status</dt>
                                    <dd className="col-sm-9 text-capitalize">
                                    {format.formatResourceStatus(resource.status)}
                                    </dd>
                                </dl> */}
                                <div className="mb-1">
                                    <Link to={`/resources/view/${resource._id}`}>
                                    <strong className="rname small">
                                        {resource.name}
                                    </strong>
                                    </Link>
                                </div>
                                <p className="small text-muted mb-1">
                                    {resource._id}
                                    <span className="small text-left ms-2">
                                    {resource.is_picked}
                                    {resource.is_picked && (
                                        <>
                                        <i className="bi bi-check-lg text-success"></i>
                                        </>
                                    )}
                                    </span>
                                </p>
                                <div className="d-flex align-items-center py-1 small mb-1">
                                    <small className="rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal px-2">
                                    {resource.slug.split("-").pop()}
                                    </small>
                                    {resource.metaSeo &&
                                    resource.metaSeo.canonicalURL ? (
                                    <>
                                        <span className="badge bg-primary bg-opacity-10 text-primary rounded-pill ms-2">
                                        {"C"}
                                        </span>
                                        <span className="badge bg-light ms-1 text-dark fw-normal ">
                                        {resource.metaSeo.canonicalURL
                                            .split("-")
                                            .pop()}
                                        </span>
                                    </>
                                    ) : (
                                    ""
                                    )}
                                    {resource.metaSeo &&
                                    resource.metaSeo.redirectURL ? (
                                    <>
                                        <span className="badge bg-danger rounded-pill ms-2">
                                        {"R"}
                                        </span>
                                        <span className="badge bg-light ms-1 text-dark">
                                        {resource.metaSeo.redirectURL
                                            .split("-")
                                            .pop()}
                                        </span>
                                    </>
                                    ) : (
                                    ""
                                    )}
                                </div>
                                <div className="text-muted mb-1 small">
                                    <Link
                                    to={`/resources/?created_by=${resource.creator.username}`}
                                    >
                                    {resource.creator.username}
                                    </Link>
                                </div>
                                <div className="small mb-1">
                                    <span className="badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                    {resource.contentType.toUpperCase()}
                                    </span>
                                    <span className="ms-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                    {resource.license.toUpperCase()}
                                    </span>
                                </div>

                                <div className="text-muted no-break mb-1 d-flex align-items-center">
                                    <span className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                    <i className="bi bi-clock me-1"></i>
                                    <span className="">
                                        <TimeAgo date={resource.created_at} />
                                    </span>
                                    </span>
                                    <Moment
                                    format="YYYY/MM/DD HH:mm:ss"
                                    className="small my-1"
                                    >
                                    {resource.created_at}
                                    </Moment>
                                    {/* {new Date(resource.created_at).toDateString()} */}
                                </div>
                                <div className="text-muted no-break mb-1 d-flex align-items-center">
                                    {resource.updated_at && (
                                    <>
                                        <span className="me-2 badge rounded-pill bg-secondary bg-opacity-10 text-secondary fw-normal py-1 px-2">
                                        <i className="bi bi-clock me-1"></i>
                                        <span className="">
                                            <TimeAgo date={resource.updated_at} />
                                        </span>
                                        </span>
                                        <Moment
                                        format="YYYY/MM/DD HH:mm:ss"
                                        className="small my-1"
                                        >
                                        {resource.updated_at}
                                        </Moment>
                                    </>
                                    )}
                                    {/* {resource.updated_at && new Date(resource.updated_at).toDateString()} */}
                                </div>
                                </div>
                                <div className="d-none p-3 item-action d-flex align-items-center justify-content-between position-absolute bottom-0 start-0 end-0">
                                <Link
                                    to={`/resources/edit/${resource._id}`}
                                    className="btn btn-sm mx-1 rounded"
                                >
                                    <i className="bi bi-pencil text-muted"></i>
                                </Link>
                                <Link
                                    to={`/resources/view/${resource._id}`}
                                    className="btn btn-sm mx-1"
                                >
                                    <i className="bi bi-eye text-muted"></i>
                                </Link>
                                <button
                                    className="btn btn-sm mx-1"
                                    onClick={(e) => {
                                    e.preventDefault();
                                    //removeResource(resource._id);
                                    }}
                                >
                                    <i className="bi bi-trash2 text-muted"></i>
                                </button>
                                {resource?.originalImage?.path && (
                                    <a
                                    href={`${MEDIA_URL}${resource?.originalImage?.path}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-sm mx-1"
                                    >
                                    <i className="bi bi-image text-muted"></i>
                                    </a>
                                )}
                                <div className="mx-1">
                                    {/* <input
                                    type="checkbox"
                                    value={resource._id}
                                    className="form-check-input"
                                    {...register(`resource_id.${index}`)}
                                    //name={resource._id}
                                    onChange={(e) => handleIdChange(e)}
                                    //checked={selectedId.includes(resource._id) ? 'checked' : ''}
                                    //   checked={
                                    //     selectedId.indexOf(resource._id.trim()) > -1
                                    //       ? true
                                    //       : false
                                    //   }
                                    /> */}
                                </div>
                                </div>
                            </div>
                            </div>
                        );
                    
                    })}
                </div>
            </div>
            
 
            </>
          )}
       </AuthLayout>
    )
}

function mapStateToProps(state) {
    const { token } = state.auth;
  
    const { message, messageType } = state.message;
    return {
      token,
      message,
      messageType,
    };
  }
  
  export default connect(mapStateToProps)(CollectionDetail);